<script>
    import appConfig from "@/app.config";
    import { required } from "vuelidate/lib/validators";

    export default {
        page: {
            title: "Create Shift",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },

        props: ["areas", "shift", "location_id","disableFromDate","disableToDate" , "shiftFromCalendar"],
        data() {
            return {
                dyn_areas: [],
                days: [{ text: "Select Day", value: null }, "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                shift_names: [],
                duplicate_shift: false,
                show: true,
                locations: [],
                locations_options: [],
                loc_id: null,
                isFull: true,
                chunkError:false,
                one_time: false,
            };
        },
          validations: {
            shift: {
                name: {
                    required,
                },
                // chunk: {
                //     required,
                // },
                from: {
                    required,
                },
                to: {
                    required,
                },
                location_id:{
                    required,
                },
                interval: {
                    required,
                },
            },
        },

        beforeMount() {
            this.fetchLocations();
        },

        mounted() {
            this.shift.duplicate = false;
            this.shift.duplicate_status = false;
            this.getShiftNames();
        },

        watch: {
            location_id: {
                handler: function (newD, old) {
                    if (newD) {
                        let e = { target: { value: newD } };
                        this.loc_id = newD;
                        this.fetchArea(e);
                    }
                },
                // immediate: true,
            },
            areas: {
                handler: function (newD, old) {
                    if(!this.one_time)
                    {
                        if (this.location_id != null) {
                            if (newD) {
                                newD.map((area) => {
                                    // area.roles = area.area_role;
                                    // this.showRole(area.id)
                                    this.dyn_areas.map((d_area) => {
                                        if (area.id == d_area.id) {
                                            area.roles = d_area.area_role;
                                        }
                                    });
                                });
                            }
                        }
                        this.one_time = true;
                    }
                },
                // immediate: true,
            },
            'shift.from': {
                handler: function (newD, old) {
                        if (newD) {
                            this.shift.interval=this.getDayname(newD);
                        }
                },
            },
            // isFull:{
            //     handler: function(newD,old){
            //         if(this.isFull == true){
            //             this.shift.chunk = 1;
            //         }
            //     }
            // },
            // 'shift.chunk':{
            //     handler: function(newD,old){
            //         if(this.shift.chunk > 3)
            //         {
            //             this.chunkError = true;
            //         }else{
            //             this.chunkError = false;
            //         }
            //     }
            // }
        },

        methods: {
            getShiftNames()
            {
                this.$axios.get('shift/name/index' , {params: {pagination : 0}}).then((response) => {
                    let data = response.data.payload.paginated;
                    this.shift_names = data;
                }).catch((error) => {
                this.handleErrorResponse(error.response, "error");
                });
            },
            fetchLocations() {
                this.$axios.get("/restaurant/location/index").then((response) => {
                    let data = response.data.payload;
                    this.locations = data;

                    this.locations_options = this.locations.map((location, index) => {
                        return {
                            text: location.name,
                            id: location.id,
                        };
                    });
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

            AddformData() {
                this.areas.push({
                    id:null,
                    area_id: null,
                    roles: null,
                    area_role: [{
                        id:null,
                        count:null,
                        role_count:null,
                        remaining_slots:null,
                    }],
                });
            },
            AddRole(role_index) {
                // this.areas.map((area) => {
                //     if (area.id == id) {
                //         area.area_role.push({
                //             role: null,
                //             count: null,
                //         });
                //     }
                // });
                this.areas[role_index].area_role.push({
                    role: null,
                    count: null,
                    role_count:null,
                    remaining_slots:null,
                });
            },

            deleteRow(index) {
                this.areas.splice(index, 1);
            },
            deleteRole(roleIndex, index) {
                this.areas[index].area_role.splice(roleIndex, 1);
                  
            },

            fetchArea(e) {
                this.locations.map((location) => {
                    if (e.target.value == location.id) {
                        if(location.areas.length == 0)
                        this.triggerSwal('The selected location has no work area', "error")
                        this.dyn_areas = location.areas;
                    }
                });
            },

            showRole(e,id) {
                this.areas.map((area) => {
                    if (area.id == id) {
                        this.dyn_areas.map((d_area) => {
                            if (e.target.value == d_area.id) {
                                area.roles = d_area.area_role;
                            }
                        });
                    }
                });
                // this.areas.map((area) => {
                //     if (area.id == id) {
                //         this.dyn_areas.map((d_area) => {
                //             if (id== d_area.id) {
                //                 area.roles = d_area.area_role;
                //             }
                //         });
                //     }
                // });
            },
            // numberFormat(e)
            // {
            //     return this.shift.chunk = e.substring(0,1);
            // }
        },

        middleware: "authentication",
    };
</script>

<template>
    <div>
        <!-- <div class="row">
            <div class="col-md-6 mb-2" dir="ltr">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="shift.duplicate" :value="true" :unchecked-value="false" checked id="flexCheckDefault" />
                    <label class="form-check-label" for="flexCheckDefault">
                        Click to Duplicate Shift
                    </label>
                </div>
            </div>
            <div class="col-md-6 mb-2">
                <p v-if="shift.duplicate_status" class="text-danger">
                    Note : This Shift is Duplicate after changes you can create again
                </p>
            </div>
        </div> -->
        <div class="row">
            <b-form>
                <div class="d-flex flex-md-row flex-column gap-3">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="d-flex align-items-center gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <!-- <b-form-group id="input-group-2" label="Shift Name" label-for="input-2">
                                            <b-form-input id="input-2" v-model="shift.name" placeholder="Enter shift Name" required></b-form-input>
                                        </b-form-group> -->
                                        <label>Shift Name</label>

                                        <b-input-group >
                                            <select v-model="shift.name"  id="shift_name" class="form-select" 
                                                    :class="{ 'is-invalid': $v.shift.name.$error }">
                                                <option :value="shift_name.name" v-for="(shift_name, index) in shift_names" :key="index">
                                                    {{ shift_name.name }}
                                                </option>
                                                <option :value="null" v-if="shift_names.length == 0" disabled>No Shift Name Found</option>
                                            </select>
                                            <b-input-group-prepend is-text class="w-25">
                                                 <b-form-input id="color" v-model="shift.label_color" type="color"></b-form-input>
                                            </b-input-group-prepend>
                                            <div
                                                v-if="!$v.shift.name.required"
                                                class="invalid-feedback"
                                                >
                                                Name is required.
                                            </div>
                                        </b-input-group>

                                        <!-- <label>Shift Name</label>

                                        <select v-model="shift.name"  id="shift_name" class="form-select" 
                                                :class="{ 'is-invalid': $v.shift.name.$error }">
                                            <option :value="shift_name" v-for="(shift_name, index) in shift_names" :key="index">
                                                {{ shift_name }}
                                            </option>
                                        </select>
                                        <div
                                            v-if="!$v.shift.name.required"
                                            class="invalid-feedback"
                                            >
                                            Name is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3" v-if="shift.name=='other'">
                                <b-form-group id="input-group-2" label="Shift Name" label-for="input-2">
                                    <b-form-input id="input-2" v-model="shift.name" placeholder="Enter shift Name" required
                                        :class="{ 'is-invalid': $v.shift.name.$error }"></b-form-input>
                                        <div
                                            v-if="!$v.shift.name.required"
                                            class="invalid-feedback"
                                            >
                                            Shift Name is required.
                                        </div>
                                    </b-form-group>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="align-items-center d-flex gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-date" label="From" label-for="time">
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.from.$error }" v-if="!shiftFromCalendar"
                                            :disabled="shift?.shift_timeline?.length > 0 ? disableFromDate:false" v-model="shift.from" :min="changeDateTimeToLocalFormats(Date())" type="datetime-local"></b-form-input>
                                        
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.from.$error }"
                                            :disabled="disableFromDate" v-model="shift.from" v-else type="time"></b-form-input>
                                        <div
                                            v-if="!$v.shift.from.required"
                                            class="invalid-feedback"
                                            >
                                            From is required.
                                        </div>
                                        </b-form-group>
                                        
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3"  >
                                <div class="align-items-center d-flex gap-2">
                                    <!-- <div class="form-field__wrapper w-100" v-if="shift.chunk != 1">
                                        <b-form-group id="example-date" label="Shift Break Count" label-for="time">
                                            <b-form-input id="time" v-model="shift.chunk" min="0" max="5" type="number"></b-form-input>
                                        </b-form-group>
                                    </div> -->

                                    <!-- <b-input-group class="mt-3">
                                        <b-input-group-prepend is-text>
                                            <b-form-checkbox  :value='true' v-model="isFull" checked plain  :disabled="disableFromDate">Full Shift</b-form-checkbox>
                                        </b-input-group-prepend>
                                        <b-form-input aria-label="Text input with checkbox"  min="1" max="3" :formatter="numberFormat" type="number" placeholder="Enter Chunks" v-model="shift.chunk" :disabled="isFull == true || disableFromDate" :class="{ 'is-invalid': $v.shift.chunk.$error }"></b-form-input>
                                        <div
                                            v-if="!$v.shift.chunk.required"
                                            class="invalid-feedback"
                                            >
                                            Chunk is required.
                                        </div>
                                    </b-input-group> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="d-flex align-items-center gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <label>Select Location</label>

                                        <select v-model="shift.location_id" @change="fetchArea($event)" id="emp_job_type" class="form-select"  :class="{ 'is-invalid': $v.shift.location_id.$error }" >
                                            <option :value="null" v-if="locations.length == 0" disabled>No Location Found</option>
                                            <option :value="location.id" v-for="(location, index) in locations" :key="index">
                                                {{ location.name }}
                                            </option>
                                        </select>
                                        <div
                                            v-if="!$v.shift.location_id.required"
                                            class="invalid-feedback"
                                            >
                                            Location is required.
                                        </div>
                                        <!-- <Select2 class="form-select"
                                                    @change="fetchArea($event)" 
                                                    v-model="loc_id"
                                                    :options="locations_options"
                                                    id="location"
                                                    /> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="align-items-center d-flex gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-date" label="To" label-for="time" class="mb-3">
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.to.$error }" :disabled="shift?.shift_timeline?.length > 0 ? disableFromDate:false" v-model="shift.to" :min="changeDateTimeToLocalFormats(shift.from)" max="" v-if="!shiftFromCalendar" type="datetime-local"></b-form-input>
                                            
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.to.$error }" :disabled="disableToDate" v-model="shift.to" :min="changeDateTimeToLocalFormats(shift.from)" max="" v-else type="time"></b-form-input>
                                            <div
                                            v-if="!$v.shift.to.required"
                                            class="invalid-feedback"
                                            >
                                                To is required.
                                            </div>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <div class="align-items-center d-flex gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-color" label="Label" label-for="color" class="mb-3">
                                            <b-form-input id="color" v-model="shift.label_color" type="color"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-md-12 mb-3">
                                <div class="d-flex align-items-center gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                       
                                        <b-form-group id="input-group-3" label="Day" label-for="input-3">
                                            <b-form-select id="input-3" class="form-select py-2" v-model="shift.interval" :options="days" required></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 mb-3">
                    <div class="d-flex align-items-center gap-2">
                       
                        <div class="form-field__wrapper w-100">
                            <b-form-group id="input-group-2" label="Description" label-for="input-2">
                                <textarea class="form-control" v-model="shift.desc" :maxlength="225" rows="3" placeholder="Write Description"></textarea>
                            </b-form-group>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-12 mb-3" v-if="dyn_areas.length > 0">
                    <div class="bg-light p-3 mb-3 rounded" v-for="(field, index) in areas" :key="index">
                    <div class="area-grid align-items-end mb-3">
                        <div class="grid-01">
                         
                                <div class="form-field__wrapper w-100">
                                    <!-- <b-form-group id="example-date" label="Area" label-for="area">
                                                     <multiselect class="text-dark" id="area" v-model="field.area" 
                                                     :options="dyn_areas" label="name" @select="showRole($event)"
                                                        track-by="name" :multiple="true"></multiselect>
                                                       
                                                    </b-form-group> -->
                                    <label>Area</label>
                                    <select id="work_area" @change="showRole($event, field.id)" v-model="field.id" class="form-select">
                                        <option :value="area.id" v-for="(area, index) in dyn_areas" :key="index">
                                            {{ area.name }}
                                        </option>
                                    </select>
                                </div>
                            
                        </div>
                        <div class="delete-fields grid-02 text-end">
                            <button type="button" class="mt-3 border-0 bg-white btn"  @click="deleteRow(index)" > Delete</button>
                        </div>
                        </div>
                        <div class="d-grid mb-3 role-grid align-items-end" v-for="(area_role, role_index) in field.area_role" :key="role_index">
                            <div class="grid-01">
                                <div class="d-flex align-items-center gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <label>Role</label>
                                        <select id="work_area" v-model="area_role.id" class="form-select">
                                            <option :value="role.id" v-for="(role, index) in field.roles" :key="index">
                                                {{ role.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="grid-02">
                                <b-form-group id="example-date" label="Count" label-for="count">
                                    <b-form-input id="count" v-model="area_role.count" type="number" required></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="delete-fields grid-03 text-end">
                                <button type="button" class="mt-3 border-0 bg-white btn" @click="deleteRole(role_index, index)" > Delete</button>
                            </div>
                        </div>
                        <div class="col-md-12 ">
                            <div class="d-flex justify-content-between">
                                <div class="add-fields">
                                    <button type="button" class="btn mt-lg-0 border-0 bg-white"  @click="AddRole(index,field.id)" > Add Role</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <div class="add-fields">
                               
                                <button type="button" class=" mt-lg-0 border-0 bg-light btn" @click="AddformData()" > Add Area</button>
                            </div>
                        </div>
                    </div>
                </div>

                <b-form-group>
                    <b-form-checkbox v-model="shift.shift_type" value="Premium" unchecked-value="Non_Premium" class="mb-3" checked plain>Premium</b-form-checkbox>
                </b-form-group>
                <div class="col-md-2 ms-4" v-if="shift.shift_type == 'Premium'">
                    <div class="input-group mb-3">
                        <span class="input-group-text">$</span>
                        <input type="number" v-model="shift.bonus" placeholder="00" class="form-control" aria-label="Amount (to the nearest dollar)" />
                    </div>
                </div>

                 <div class="col-md-12 mb-3">
                    <div class="align-items-center d-flex gap-2">
                        <!-- <b-form-group>
                            <b-form-checkbox v-model="shift.chunk" value="1" unchecked-value="0" class="mb-3" checked plain>Full Shift</b-form-checkbox>
                        </b-form-group> -->
                    </div>
                </div>

            </b-form>
        </div>
    </div>
</template>
<style scoped>
.input-group-text {
    height: 39px;
}
</style>
